import type { IFAQMeta } from '@sravni/types/lib/seo';

import { escapeHTMLTags } from '@src/helpers/seo';
import type { ICreateSchemaParams, SchemaJsonLD } from 'entities/Seo/types';

const FAQ_DEFAULT = [
  {
    '@type': 'Question',
    name: 'Где можно выгоднее взять микрозайм?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Воспользуйтесь нашим сервисом на Сравни, чтобы сравнить предложения разных МФО и взять микрозайм на выгодных условиях. Обращайте внимание на:Процентные ставки и акционные предложения.Условия для новых и постоянных клиентов.Репутацию и рейтинг МФО.',
    },
  },
  {
    '@type': 'Question',
    name: 'Можно ли продлить срок микрозайма?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Да, большинство МФО предоставляют возможность продления срока микрозайма при уплате начисленных процентов. Условия пролонгации:Обратитесь в МФО до наступления даты погашения.Оплатите проценты за фактический срок пользования займом.Заключите дополнительное соглашение о продлении срока.',
    },
  },
  {
    '@type': 'Question',
    name: 'Какие документы нужны для получения крупного микрозайма?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Для получения крупного микрозайма (от 100 000 рублей) могут потребоваться:Паспорт и второй документ (права, СНИЛС).Справка о доходах или выписка из банка.Залоговое имущество (например, ПТС автомобиля).Поручительство физических лиц (в некоторых случаях).',
    },
  },
  {
    '@type': 'Question',
    name: 'Можно ли получить микрозайм без отказа?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Полностью гарантировать микрозайм без отказа невозможно, но для увеличения шансов:Выбирайте МФО с высокой лояльностью к заемщикам.Убедитесь, что соответствуете требованиям МФО.Проверьте корректность всех данных в заявке.',
    },
  },
  {
    '@type': 'Question',
    name: 'Как выбраться из долговой ямы по микрозаймам?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Если вы оказались в долговой яме по микрозаймам:Свяжитесь с МФО и обсудите возможность реструктуризации или отсрочки платежа.Обратитесь к близким за финансовой поддержкой.Поиск дополнительного дохода: временная подработка поможет собрать необходимую сумму.Консультируйтесь с финансовым юристом для оценки возможности банкротства физического лица.',
    },
  },
  {
    '@type': 'Question',
    name: 'Что будет, если не платить микрозайм вовремя?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'В случае просрочки:Начисляются штрафы и пени, увеличивая сумму долга.Портится кредитная история, затрудняя получение займов в будущем.МФО могут передать долг коллекторам или обратиться в суд.Судебные приставы могут взыскать долг через удержание из заработной платы или арест имущества.',
    },
  },
  {
    '@type': 'Question',
    name: 'Как влияет микрозайм на кредитную историю?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Микрозаймы влияют на кредитную историю:Положительно, если вы вовремя погашаете займы.Негативно, если допускали просрочки или не выплачивали долг.Важно: микрозаймы отражаются в кредитной истории и могут повлиять на решения банков при выдаче крупных кредитов.',
    },
  },
  {
    '@type': 'Question',
    name: 'Какие риски при обращении в нелегальные МФО?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Обращаясь в нелегальные микрофинансовые организации, вы можете столкнуться со следующими рисками:Отсутствие защиты прав заемщика: такие компании не контролируются государством, и ваши права могут быть нарушены без возможности их защитить.Завышенные процентные ставки и скрытые комиссии: нелегальные МФО могут начислять необоснованно высокие проценты и добавлять скрытые платежи.Незаконные методы взыскания долгов: возможны угрозы, психологическое давление и другие противозаконные действия при попытке взыскать долг.Важно: всегда проверяйте МФО в реестре Банка России.На Сравни мы тщательно проверили все микрофинансовые организации в нашем списке. Вы можете быть уверены, что они работают легально и прозрачно, не будут обманывать вас или навязывать скрытые комиссии. Наша цель — помочь вам безопасно и выгодно оформить микрозайм, предоставляя только надёжные и проверенные МФО.',
    },
  },
  {
    '@type': 'Question',
    name: 'Что такое микрозайм?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Микрозайм – это краткосрочный заем, предоставляемый микрофинансовыми организациями (МФО) на небольшие суммы (обычно до 100 000 рублей) с минимальным пакетом документов и быстрым рассмотрением заявки. В отличие от банковских кредитов, микрозаймы доступны широкому кругу лиц, включая тех, у кого плохая кредитная история или нет официального трудоустройства.',
    },
  },
  {
    '@type': 'Question',
    name: 'Почему некоторые микрозаймы можно взять под 0%?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Микрозайм под 0% – это специальное предложение для новых клиентов. МФО таким образом привлекают заемщиков, предлагая оформить первый микрозайм без процентов. Обычно условия следующие:Сумма займа: ограничена (до 30 000 рублей).Срок займа: короткий (до 30 дней).Важно: при нарушении срока погашения начисляются стандартные проценты и штрафы.',
    },
  },
  {
    '@type': 'Question',
    name: 'Сколько и на какой срок можно взять денег?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Сумма и срок микрозайма зависят от условий конкретной МФО:Сумма микрозайма: от 1 000 до 100 000 рублей (для новых клиентов обычно ограничена 30 000 рублей).Срок микрозайма: от 5 до 30 дней. Для крупных займов возможны сроки до нескольких месяцев.',
    },
  },
  {
    '@type': 'Question',
    name: 'Как выбрать выгодный микрозайм?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Чтобы выбрать выгодный микрозайм, обратите внимание на:Процентную ставку (ПСК): чем ниже, тем лучше.Сумму и срок: соответствуют ли они вашим потребностям.Условия погашения: наличие комиссий за досрочное погашение.Репутацию МФО: почитайте отзывы клиентов.Акции и предложения: многие МФО предлагают скидки и бонусы.',
    },
  },
  {
    '@type': 'Question',
    name: 'Можно ли оформить микрозайм с плохой кредитной историей?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Да, многие МФО готовы выдать микрозайм с плохой кредитной историей. Однако:Процентная ставка может быть выше.Сумма займа может быть ограничена.Вероятность одобрения все же выше, чем в банке',
    },
  },
  {
    '@type': 'Question',
    name: 'Какие типы микрозаймов предлагают МФО?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'МФО предлагают различные виды микрозаймов:Микрозаймы до зарплаты – небольшие суммы на короткий срок (от 5 до 30 дней).Крупные микрозаймы для предпринимателей – суммы до 1 000 000 рублей для развития бизнеса.Займы под залог ПТС – получение средств под залог автомобиля.Онлайн микрозаймы – оформление через интернет с зачислением на банковскую карту или электронный кошелек.Микрозаймы наличными – выдача денег в офисе МФО или через системы денежных переводов.',
    },
  },
  {
    '@type': 'Question',
    name: 'Как оформить микрозайм онлайн?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Чтобы оформить микрозайм онлайн, следуйте этим шагам:Выберите МФО с подходящими условиями на нашем сайте.Заполните онлайн-заявку, указав личные данные и информацию о доходах.Дождитесь решения – обычно это занимает несколько минут.Получите деньги на банковскую карту, электронный кошелек или другим удобным способом.',
    },
  },
  {
    '@type': 'Question',
    name: 'Что потребуется от заемщика для получения микрозайма?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Основные требования к заемщику:Гражданство РФ и постоянная регистрация.Возраст от 18 до 70 лет (в некоторых МФО возрастные ограничения могут отличаться).Паспорт гражданина РФ.Контактный номер телефона и электронная почта.Банковская карта на ваше имя для зачисления средств (при онлайн оформлении).',
    },
  },
  {
    '@type': 'Question',
    name: 'Как погасить микрозайм?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Погасить микрозайм можно различными способами:Через личный кабинет на сайте МФО с помощью банковской карты.Банковским переводом по реквизитам микрофинансовой организации.В платёжных терминалах или банкоматах.В салонах связи (Связной, Евросеть и другие).Через электронные кошельки (например, ЮMoney).',
    },
  },
  {
    '@type': 'Question',
    name: 'Как увеличить шансы на одобрение микрозайма?',
    acceptedAnswer: {
      '@type': 'Answer',
      text: 'Для повышения шансов на одобрение:Указывайте достоверную информацию в заявке.Избегайте ошибок и опечаток при заполнении анкеты.Погасите текущие просрочки по другим кредитам.Предоставьте дополнительные документы, если это возможно (например, справку о доходах).Подайте заявку сразу в несколько МФО.',
    },
  },
];

export const mapFAQtoSchema = ({ seo }: ICreateSchemaParams): SchemaJsonLD.FAQPage => {
  const faq = seo?.contentBlocks?.find((value): value is IFAQMeta => value.type === 'faq');

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity:
      faq?.serviceResponseData.items?.reduce(
        (acc, { question, answer }) => [
          ...acc,
          {
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: escapeHTMLTags(answer),
            },
          },
        ],
        [],
      ) || FAQ_DEFAULT,
  };
};
